import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import { Edges, Spacer, Post, FlexibleContent } from "components"
import * as theme from "theme"

const PostSingle = props => {
  const {
    data: {
      post: {
        id,
        title,
        uri,
        acf: { modules },
      },
      posts,
    },
  } = props

  const renderPost = index => {
    const o = posts?.nodes && posts.nodes[index]

    return (
      <RelatedPost
        image={o?.acfPost?.featuredImage}
        headline={o?.title}
        url={o?.uri}
        backgroundColor={"#fff"}
      />
    )
  }

  let prevPost, nextPost

  const index = posts?.nodes && posts.nodes.map(o => o.id).indexOf(id)

  if (index === 0) {
    prevPost = posts.nodes.length - 1
    nextPost = index + 1
  } else if (index === posts?.nodes?.length - 1) {
    prevPost = index - 1
    nextPost = 0
  } else {
    prevPost = index - 1
    nextPost = index + 1
  }

  return (
    <>
      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            title,
            uri,
          }}
        />
      )}

      {posts?.nodes.length > 1 && (
        <RelatedPostsContainer>
          <Edges size="md">
            <Spacer pt={60} pb={{ xs: 20, sm: 60 }}>
              <Spacer mb={40}>
                <Grid container alignItems="center" justify="space-between">
                  <Spacer mb={{ xs: 30, sm: 0 }} mr={30}>
                    <Typography variant="h4" children="Other Posts" />
                  </Spacer>
                </Grid>
              </Spacer>

              <Grid container justify="space-between">
                {renderPost(prevPost)}
                {posts?.nodes.length > 2 && renderPost(nextPost)}
              </Grid>
            </Spacer>
          </Edges>
        </RelatedPostsContainer>
      )}
    </>
  )
}

const RelatedPostsContainer = styled.div`
  background: ${theme.colors.background.dark};
`

const RelatedPost = styled(Post)`
  width: 100%;
  margin-bottom: 40px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 20px);
    margin-bottom: 0;
  }
`

export default PostSingle

export const CollectionQuery = graphql`query DefaultSinglePost($id: String!) {
  post: wpPost(id: {eq: $id}) {
    id
    title
    uri
    date
    acf {
      modules {
        __typename
        ... on WpPost_Acf_Modules_TextImageColumns {
          columns
          imagePlacement
          items {
            text
            mobileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 768, layout: CONSTRAINED)
                }
              }
            }
          }
          desktopBannerImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
        }
        ... on WpPost_Acf_Modules_CompleteTheLook {
          headline
          items {
            overrideProductImage
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            product {
              __typename
              ... on WpProduct {
                id
                uri
                product {
                  ...ProductFragment
                }
              }
            }
          }
        }
        ... on WpPost_Acf_Modules_Accordion {
          headline
          items {
            expanded
            headline
            content
          }
        }
        ... on WpPost_Acf_Modules_TwoImages {
          image1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 85, layout: FULL_WIDTH)
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 85, layout: FULL_WIDTH)
              }
            }
          }
          variant
        }
        ... on WpPost_Acf_Modules_Columns {
          size
          columns
          items {
            button {
              target
              title
              url
            }
            buttonStyle
            text
          }
        }
        ... on WpPost_Acf_Modules_Hero {
          useDifferentVideoForMobile
          keepAspectRatio
          videoType
          youtubeUrl
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          video {
            localFile {
              publicURL
            }
          }
          autoplay
          mobileVideo {
            keepAspectRatio
            videoType
            youtubeUrl
            video {
              localFile {
                publicURL
              }
            }
          }
        }
        ... on WpPost_Acf_Modules_Text {
          text
          button {
            target
            title
            url
          }
          buttonStyle
        }
        ... on WpPost_Acf_Modules_Form {
          formId
        }
        ... on WpPost_Acf_Modules_BannerImage {
          desktopImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          mobileImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 960, quality: 90, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
  posts: allWpPost {
    nodes {
      id
      title
      uri
      date
      acfPost {
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`
